import { useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import {
  AiFillAppstore,
  AiFillDatabase,
  AiOutlineRise,
  AiFillSignal,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import Contactlist from "../contact/Contactlist";
import logo from "./../../assets/images/logo.png";
function SideBar() {
  const handelClick = () => { };
  return (
    <div>
      {" "}
      <div className="bg-light">
        <img src='https://neokred.s3.ap-south-1.amazonaws.com/Navbar/Group+1000004562.svg' width="80%" height="80%" className="m-auto" />
      </div>
      <div>
        <p className=" cursor fs-5">
          <Link to="/dashboard">Dashboard </Link>
        </p>
        <p className=" cursor fs-5">
          <Link to="/blog-list" className="">
            Blogs{" "}
          </Link>
        </p>
        <p className=" cursor fs-5">
          <Link to="/career-list" className="">
            Careers{" "}
          </Link>
        </p>
        <p className=" cursor fs-5">
          <Link to="/SubscribeList"> Subscribe List </Link>
        </p>
        <p className=" cursor fs-5">
          <Link to="/contact-list"> Contact Us</Link>
        </p>

      </div>
    </div>
  );
}

export default SideBar;
